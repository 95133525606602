<template>
  <div id="ai-services_nlp_pos-tag">
    <ner-entities-dialog
      :visible.sync="showEntitiesDialog"
      :entity-config="entities"
    />
    <service-template
      :class="{ 'nlp-example-rtl': currentLanguage === $consts.lang.AR }"
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :run-disabled="runDisabled"
      :loading="loading"
      service="nlp"
      :icon="require('@/assets/images/icons/services/header/POS.svg')"
    >
      <template v-slot:header>
        {{ $t('services.POS.header') }}
      </template>
      <template v-slot:left>
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="currentLanguage"
          :loading="loading"
        />
        <example-text
          :step="2"
          :items="textExamples"
          :current-example.sync="currentExample"
          :example-text.sync="exampleText"
          :loading="loading"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="3"
          :service-name="$t('services.POS.header')"
          :loading="loading"
        >
          <div v-if="result && result.length">
            <div class="result__header">
              <h4 class="result__header__title">
                <span>{{ $t('services.POS.categories') }}</span>
                <i
                  class="material-icons"
                  @click="openEntitiesDialog"
                >info</i>
              </h4>
              <span class="result__header__subtitle">{{
                $t('services.POS.headerSubtitle')
              }}</span>
            </div>
            <div class="tag__container result">
              <tag
                v-for="(item, index) in result"
                :key="index"
                :word="item.word"
                :label="item.label"
                :class-name="tagClassNameByLanguage(item.label)"
                :lang="currentLanguage"
              />
            </div>
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ExampleText from '@/views/pages/AIServices/components/ExampleText/Index.vue';
import NerEntitiesDialog from '@/views/pages/AIServices/components/NerEntitiesDialog/Index.vue';
import { constants } from '@/libs/constants';
import Tag from './Tag/Index.vue';
import TagAr from './Tag/tags_ar.json';
import TagEN from './Tag/tags_en.json';

export default {
  name: 'POS',
  components: {
    NerEntitiesDialog,
    ExampleText,
    GeneratedResult,
    InputLanguage,
    ServiceTemplate,
    Tag,
  },
  data: () => ({
    availableLanguages: [constants.lang.EN, constants.lang.AR],
    currentLanguage: constants.lang.EN,
    entities: constants.posEntityConfig,
    showEntitiesDialog: false,
    currentExample: null,
    examples: [],
    exampleText: '',
    result: null,
    loading: false,
    tagAr: TagAr,
    tagEn: TagEN,
  }),
  computed: {
    textExamples() {
      return (this.examples[this.currentLanguage] || []).map((el, index) => ({
        id: index,
        value: el,
      }));
    },
    runDisabled() {
      return !this.exampleText || !this.currentLanguage;
    },
  },
  watch: {
    currentLanguage() {
      this.currentExample = 0;
      this.result = null;
    },
    currentExample() {
      this.result = null;
    },
    exampleText() {
      this.result = null;
    },
  },
  async created() {
    this.examples = await import('@/../public/examples/NLP/POS.json');
    this.currentExample = 0;
  },
  methods: {
    async run() {
      this.loading = true;
      return this.$api.nlp.getPOS({
        lang: this.currentLanguage,
        text: this.exampleText,
      });
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data.doc_pos_tags;
    },
    runError() {
      this.loading = false;
    },
    openEntitiesDialog() {
      this.showEntitiesDialog = true;
    },
    tagClassNameByLanguage(label) {
      if (this.currentLanguage === constants.lang.EN) {
        if (this.tagEn[label]) {
          return this.tagEn[label].className;
        }
      }
      if (this.currentLanguage === constants.lang.AR) {
        if (this.tagAr[label]) {
          return this.tagAr[label].className;
        }
      }
      return 'NNN';
    },
  },
};
</script>

<style lang="scss" scoped>
#ai-services_nlp_pos-tag {
  background: $background-secondary;
  .result {
    display: flex;
    &__header {
      color: $text-primary;
      &__title {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 0.16rem;
      }
      i {
        margin-left: 0.12rem;
        cursor: pointer;
      }
      &__subtitle {
        font-size: 0.12rem;
      }
    }
  }
  /deep/ .tag__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.14rem;
  }
}
</style>

<style lang="scss">
@import './colors.scss';
.tooltip {
  @each $name, $color in $colors {
    &__#{$name}.el-tooltip__popper.is-dark {
      background-color: $color;
      font-size: 0.14rem;
      font-family: Montserrat;
      padding: 0.07rem 0.1rem;
      .popper__arrow {
        border-top-color: $color;

        &::after {
          border-top-color: $color;
        }
      }
    }
  }
}
</style>
