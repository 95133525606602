<template>
  <div
    class="tag"
    :class="className"
    @mouseover="active = true"
    @mouseleave="active = false"
  >
    <el-tooltip
      :content="tagLabelByLanguage"
      effect="dark"
      :open-delay="300"
      placement="top"
      :popper-class="'tooltip__' + className"
    >
      <span class="tag__text">{{ word }}</span>
    </el-tooltip>
    <svg
      class="tag__icon"
      width="5"
      height="23"
      viewBox="0 0 5 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.70948 0.858887 4.71836 2 4.94999V23H3V4.94999C4.14111 4.71836 5 3.70948 5 2.5C5 1.11929 3.88071 0 2.5 0Z"
        fill="#E00824"
      />
    </svg>
  </div>
</template>

<script>
import { constants } from '@/libs/constants';
import TagAr from './tags_ar.json';
import TagEN from './tags_en.json';

export default {
  name: 'Tag',
  props: {
    word: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      tagAr: TagAr,
      tagEn: TagEN,
    };
  },
  computed: {
    tagLabelByLanguage() {
      const { label } = this;
      if (this.lang === constants.lang.EN) {
        if (this.tagEn[label]) {
          return this.tagEn[label].text;
        }
      }
      if (this.lang === constants.lang.AR) {
        if (this.tagAr[label]) {
          return this.tagAr[label].text;
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../colors.scss';

.tag {
  position: relative;
  margin-top: 0.26rem;
  margin-right: 0.07rem;
  padding: 0.01rem 0.04rem;

  &__tip {
    width: 0px;
    height: 0px;
    position: absolute;
    background: transparent;
    border: 0.07rem solid;
    bottom: -0.14rem;
    left: 50%;
    transform: translate(-50%, 0);
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  &__icon {
    position: absolute;
    top: -0.03rem;
    right: -0.02rem;
    height: 0.23rem;
  }
}
@each $name, $color in $colors {
  .#{$name} {
    background: rgba($color, 0.1);
    .tag__icon {
      path {
        fill: $color;
      }
    }
  }
}
</style>
