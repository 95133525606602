<template>
  <el-dialog
    :visible="visible"
    :before-close="handleClose"
    class="ner-entities-dialog"
    width="auto"
  >
    <div
      slot="title"
      class="title"
    >
      Categories
    </div>
    <div class="entities">
      <div style="flex: 1">
        <div
          v-for="(entity, index) in leftColumnEntities"
          :key="entity.label"
          class="entity-row"
        >
          <span class="entity-number">{{ index + 1 }}</span>
          <span
            v-if="!entity.icon"
            class="entity-color-box"
            :style="{ background: entity.color }"
          />
          <font-awesome-icon
            v-if="entity.icon"
            class="icon"
            :style="{ color: entity.color }"
            :icon="entity.icon"
          />
          <span>{{ entity.label }}</span>
        </div>
      </div>
      <div v-if="rightColumnEntities.length > 0">
        <div
          v-for="(entity, index) in rightColumnEntities"
          :key="entity.label"
          class="entity-row"
        >
          <template v-if="!entity.placeholder">
            <span class="entity-number">{{ leftColumnEntities.length + index + 1 }}</span>
            <span
              v-if="!entity.icon"
              class="entity-color-box"
              :style="{ background: entity.color }"
            />
            <font-awesome-icon
              v-if="entity.icon"
              class="icon"
              :style="{ color: entity.color }"
              :icon="entity.icon"
            />
            <span>{{ entity.label }}</span>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'NerEntitiesDialog',
  props: {
    visible: {
      type: Boolean,
    },
    entityConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entities: Object.values(this.entityConfig),
    };
  },
  computed: {
    leftColumnEntities() {
      if (this.entities.length <= this.$consts.nerEntitiesDialogPerColumn) {
        return this.entities;
      }

      return this.entities.slice(0, Math.ceil(this.entities.length / 2));
    },
    rightColumnEntities() {
      if (this.entities.length <= this.$consts.nerEntitiesDialogPerColumn) {
        return [];
      }

      const entities = this.entities.slice(Math.ceil(this.entities.length / 2), this.entities.length);

      if (entities.length % 2 === 1) {
        entities.push({ label: 'placeholder', placeholder: true });
      }

      return entities;
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style lang="scss">
.ner-entities-dialog {
  [role='dialog'] {
    max-width: 645px;
    border-radius: 14px;
    padding: 10px 20px 20px 20px;
  }
}
</style>
<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 0.24rem;
}

.entities {
  display: flex;
  font-size: 0.14rem;
}

.entity-row {
  min-width: 160px;
  display: flex;
  padding: 7px 0;
  border-bottom: 1px solid rgba(216, 221, 237, 0.8);
  font-weight: 500;
  font-size: 0.14rem;
  align-items: center;

  .icon {
    margin: 0 0.05rem;
  }

  &:last-child {
    border-bottom: none;
  }
}

.entity-number {
  width: 20px;
  text-align: center;
  align-items: center;
}

.entity-color-box {
  display: inline-block;
  border-radius: 4px;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 5px;
}
</style>
